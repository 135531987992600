import React from 'react';
import { useTranslation } from 'react-i18next';
import {Button, Modal} from "reactstrap";
import Confetti from 'react-confetti'
import "./index.scss"

const Index = props => {
  const { t } = useTranslation();

  return(
    <div className="achievementModalMain confetti-container">

      <Confetti/>

      <Modal
        isOpen={true}
        centered={true}
        size="lg"
        toggle={() => {props.close()}}
        className="achievementModal"
        backdrop={"static"}
      >
        <div className="modal-body">
          <Button className="achievementClose" color="success" onClick={() => props.close()}> {t('close')} </Button>
          <div className="achievementModalTitle"> {t('pluses_page.congratulations')} </div>
          <div className="achievementModalDesc">  {t('pluses_page.congratulations_msg')} </div>
        </div>
      </Modal>
    </div>
  )
};


export default Index