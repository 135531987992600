import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Select from "react-select";
import {Container, Card, CardTitle, CardBody, CardFooter, Label, CardHeader} from "reactstrap";
import DatePicker from "react-datepicker";

import {History, Earbuds, NavigateBefore, NavigateNext} from "@mui/icons-material";
import {getActivityLogByCompany, getActivityLogUsersByCompany} from "../../store/actions/activityLog";
import {getCompaniesOnly} from "../../store/actions/company";
import {LoaderSpinner} from "../../components/Common/Loader";

import {customDropdownPaginationStyles, customDropdownStyles} from "../../helpers/dropdown";
import {helperFormatDateWithMonthName} from "../../helpers/main";
import { saveAs } from 'file-saver';
import { utils,  write } from 'xlsx';

import './index.scss';
import "react-datepicker/dist/react-datepicker.css"
import LeafIcon from "../../components/Common/Leaf/leafIcon";

const leafColors = [
  {id: 1, color: "#4FC878", secondColor: "#A7EEBF"},
  {id: 2, color: "#FD974F", secondColor: "#FFCEAC"},
  {id: 3, color: "#4433CD", secondColor: "#E3C9FC"}
];

const categoryType = (type, data) => {
  if(type === "green_step") {
    let catId = data.category_id ? data.category_id : 1;
    let leafColor = leafColors.find(color => color.id === catId);
    return <LeafIcon
      id={"log_leaf_icon"}
      colors={leafColor}
      percentage={10}
    />
  }
  if(type === 'plan') return <Earbuds />;
  return <History/>
};

const days = ['Mán', 'Þri', 'Mið', 'Fim', 'Fös', 'Lau', 'Sun'];
const months = ['janúar', 'febrúar', 'mars', 'apríl', 'maí', 'júní', 'júlí', 'ágúst', 'september', 'október', 'nóvember', 'desember'];
const locale = {localize: {day: n => days[n], month: n => months[n]}, formatLong: {date: () => 'dd/mm/yyyy'}};



const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();

  const {activityLogs, loading, activityLogUsers, loadingUsers} = useSelector(state => state.activityLog);
  const {companies2} = useSelector( state => state.company);

  const types = [{label:t('log_page.all'), value: 'all'}, {label: t('pluses'), value: "green_step"}, {label: t('actionPlan'), value: "plan"}];
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({label:t('log_page.all'), value: 'all'});
  const [selectedType, setSelectedType] = useState({label:t('log_page.all'), value: 'all'});
  const [selectedUser, setSelectedUser] = useState({label:t('log_page.all'), value: 'all'});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [logs, setLogs] = useState([]);

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;
  const [pageTotal, setPageTotal] = useState(1);

  useEffect(() => {
    dispatch(getActivityLogByCompany(null));
    dispatch(getActivityLogUsersByCompany(null));
    dispatch(getCompaniesOnly());
  }, []);

  useEffect(() => {
    if(activityLogs && activityLogs.results) {
      let total = Math.ceil(activityLogs.results.length / pageSize);
      let pagesList = [];
      for(let i = 1; i <= total; i++) {
        pagesList.push({selectedLabel: `síða ${i} af ${total}`, label: <div><span className="m_list">{i}</span><span className="m_select">síða {i} af {total}</span></div>, value: i})
      }
      setPageTotal(total);
      setPages(pagesList);
      setLogs(activityLogs.results.slice(0, pageSize));
    }
  }, [activityLogs]);

  useEffect(() => {
    if(companies2 && companies2.results) {
      let sortedData = companies2.results.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      let data = sortedData.map(item => {return {label: item.name, value: item.id}});
      data.unshift({label: t('log_page.all'), value: 'all'});
      setCompanyList(data);
    }
  }, [companies2]);

  if (loading || loadingUsers) return (<LoaderSpinner/>);

  const sortingData = (event, sortingType) => {

    let newData = activityLogs.results;
    let type = selectedType.value;
    let user = selectedUser.value;
    let dateFrom = selectedDateFrom;
    let dateTo = selectedDateTo;

    if(sortingType==='type') {
      setSelectedType(event);
      type = event.value;
    } else if(sortingType==='user') {
      setSelectedUser(event);
      user = event.value;
    } else if(sortingType==='dateFrom') {
      setSelectedDateFrom(event);
      dateFrom = event;
    } else if(sortingType==='dateTo') {
      setSelectedDateTo(event);
      dateTo = event;
    }

    if(type && type !== 'all') {
      newData = newData.filter(item => item.data.type && item.data.type === type);
    }
    if(user && user !== 'all') {
      newData = newData.filter(item => item.data.user_id && item.data.user_id === user);
    }
    if(dateFrom) {
      let dateFromTime = new Date(dateFrom).getTime();
      newData = newData.filter(item => item.data.created_at && new Date(item.data.created_at).getTime() > dateFromTime);
    }
    if(dateTo) {
      let dateToTime = new Date(dateTo).setHours(23,59,59,0);
      newData = newData.filter(item => item.data.created_at && new Date(item.data.created_at).getTime() < dateToTime);
    }

    if(sortingType === 'page') {
      newData = newData.slice(pageSize*(event.value-1), event.value * pageSize);
      setCurrentPage(event.value);
    } else {
      let total = Math.ceil(newData.length / pageSize);
      let pagesList = [];
      for(let i = 1; i <= total; i++) {
        pagesList.push({label:<div><span className="m_list">{i}</span><span className="m_select">síða {i} af {total}</span></div>, value: i})
      }
      newData = newData.slice(0, pageSize);
      setPageTotal(total);
      setPages(pagesList);
      setCurrentPage(1);
    }

    setLogs(newData);
  };

  const changeCompany = (event) => {
    setSelectedCompany(event);
    if(event.value === 'all') {
      dispatch(getActivityLogByCompany(null));
      dispatch(getActivityLogUsersByCompany(null));
    } else {
      dispatch(getActivityLogByCompany(event.value));
      dispatch(getActivityLogUsersByCompany(event.value));
    }
  };

  const onClickExportData = () => {
    let excelCompaniesData = [];

    activityLogs.results.forEach((activityLog,index) => {
      let logData = activityLog.data ? activityLog.data : false;
      let greenStepCategory = logData && logData.type === 'green_step' ? logData.category.split('-') : false
      excelCompaniesData.push({
        'Date': logData && logData.created_at ? helperFormatDateWithMonthName(logData.created_at) : '',
        'Type': logData ? logData.type === 'green_step' ? 'Green step' : logData.type : 'History',
        'Category': greenStepCategory && greenStepCategory.length>0 ? greenStepCategory[0] : '',
        'Sub category': greenStepCategory && greenStepCategory.length>1 ? greenStepCategory[1] : '',
        'User': logData ? logData.user_name : '',
        'Company': logData ? logData.company_name : '',
        'Description': logData ? logData.description : '',
      })
    });

    if(activityLogs.results.length === excelCompaniesData.length) {
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, utils.json_to_sheet(excelCompaniesData), 'History');
      const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
      saveAs(blob, `History Data.xlsx`);
    }

  }

  return (
    <div className={`page-content activityLogPage isAdmin`}>
      {alert}

      <div className="row completedContainer">
        <div className="col cols-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
          <Card>
            <CardHeader>
              <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onClickExportData()}>
                Export Data
              </button>
            </CardHeader>
            <CardTitle>
              <div className="row">
                  <div className="col-3">
                    <Label className="form_label">{t('log_page.company')}</Label>
                    <Select
                      placeholder={t('plan_page.select')}
                      value={selectedCompany}
                      onChange={(event) => changeCompany(event)}
                      styles={customDropdownStyles()}
                      options={companyList}
                    />
                  </div>
                <div className={"col-2"}>
                  <Label className="form_label">{t('log_page.category')}</Label>
                  <Select
                    isSearchable={false}
                    placeholder={t('plan_page.select')}
                    value={selectedType}
                    onChange={(event) => sortingData(event, "type")}
                    styles={customDropdownStyles()}
                    options={types.map(item => item)}
                  />
                </div>
                <div className="col-3">
                  <Label className="form_label">{t('log_page.assignee')}</Label>
                  <Select
                    placeholder={t('plan_page.select')}
                    value={selectedUser}
                    onChange={(event) => sortingData(event, "user")}
                    styles={customDropdownStyles()}
                    options={activityLogUsers.results ? [{label:t('log_page.all'), value: 'all'}, ...activityLogUsers.results.map(item => {return {label: item.name, value: item.id}})] : []}
                  />
                </div>
                <div className="col-2">
                  <Label className="form_label">{t('log_page.date_from')}</Label>
                  <DatePicker
                    selected={selectedDateFrom}
                    isClearable
                    onChange={(event) => sortingData(event, "dateFrom")}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={locale} className="form-control form_datepicker react-datepicker"
                  />
                </div>
                <div className="col-2">
                  <Label className="form_label">{t('log_page.date_to')}</Label>
                  <DatePicker
                    selected={selectedDateTo}
                    isClearable
                    onChange={(event) => sortingData(event, "dateTo")}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={locale} className="form-control form_datepicker react-datepicker"
                  />
                </div>
              </div>
            </CardTitle>

            <CardBody>
              {logs.map((activityLog, logIndex) => {
                let logData = activityLog.data ? activityLog.data : false;
                return (
                  <Container key={"activityLog_"+logIndex}>
                    <div className="logRow logDate">{logData && logData.created_at ? helperFormatDateWithMonthName(logData.created_at) : ''}</div>
                    <div className="logRow">
                      <span className={`logType ${logData ? logData.type : ''}`}>
                        {logData ? categoryType(logData.type, logData) : <History/>}
                      </span>
                      <span className="logCategory">{logData.category || ''}</span>
                    </div>
                    <div className="logRow desc">
                      <span className="logUser">
                        {logData.user_name || ''}
                        {logData && logData.company_name ? ' - '+logData.company_name : ''}
                      </span>
                      <span className="logDesc">{logData.description || ''}</span>
                    </div>
                  </Container>
                )
              })}
            </CardBody>
            <CardFooter>
              {pageTotal > 1 &&
              <div className="pagination">
                <NavigateBefore className={`arrow prev ${currentPage === 1 && 'disabled'}`} onClick={() => sortingData({label: currentPage-1, value: currentPage-1}, 'page')}/>
                <Select
                  isSearchable={false}
                  value={pages.find(item => item.value === currentPage)}
                  onChange={(event) => sortingData(event, 'page')}
                  styles={customDropdownPaginationStyles}
                  options={pages}
                />
                <NavigateNext className={`arrow next ${currentPage === pages.length && 'disabled'}`} onClick={() => sortingData({label: currentPage+1, value: currentPage+1}, 'page')}/>
              </div>
              }
            </CardFooter>
          </Card>
          <br/><br/><br/><br/><br/><br/>
        </div>
      </div>

    </div>
  )
};

export default Index