import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Oval} from "react-loader-spinner";
import {useTranslation} from "react-i18next";
import pdfIcon from "../../../assets/images/pdf_icon_add.png";
import pdfIcon1 from "../../../assets/images/pdf_icon_add2.png";
import pdfIcon2 from "../../../assets/images/pdf_icon_add3.png";
import "./fileupload.scss"

const ovalLoader = <Oval
  height={20}
  width={20}
  color="#4cd74d"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel='oval-loading'
  secondaryColor="#4fa94d"
  strokeWidth={3}
  strokeWidthSecondary={3}
/>;

const FileUploadPdf2 = (props) => {
  const { t } = useTranslation();
  const {loadingUpload} = useSelector(state => state.companyEnvironmental);
  const [msg, setMsg] = React.useState(props.msg || t('upload_choose_pdf_file'));
  const disable  = Object.hasOwn(props, 'disabled') ? props.disabled : false;

  useEffect(() => {
    if(props.msg && props.msg !== msg) {
      setMsg(props.msg);
    }
  },[props.msg])

  const handleFileChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    if (e.target.value.length == 0) {
      setMsg(<span>{t('upload_not_selected')}</span>);
      return;
    }
    let newFile = e.target.files[0];
    var fileTypes = props.fileTypes ? props.fileTypes : ['pdf'];  //acceptable file types
    var extension = newFile && newFile.name.split('.').pop().toLowerCase(),  //file extension from input file
      isSuccess = fileTypes.indexOf(extension) > -1;  //is extension in acceptable types

    if (!isSuccess) {
      setMsg(<span className="text-danger">{t('upload_file_type')+'('+fileTypes+')'}</span>);
      return;
    }

    if(newFile && newFile.size >= 5500000) {
      setMsg(<span className="text-danger">{t('upload_file_size')+'5MB'}</span>);
      return;
    }

    reader.onloadend = () => {
      setMsg(<span>{t('doc_upload_success')}</span>);
      props.onUploadPDF(reader.result, newFile.name);
    };
    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };

  return (
    <div className={`pdfUploaderContainer ${disable && 'disabled'}`} style={props.containerStyle}>
      <img
        src={
          props && props.mainCategoryIndex ?
            (props.mainCategoryIndex === 2 ? pdfIcon2 : props.mainCategoryIndex === 1 ? pdfIcon1 : pdfIcon)
            : pdfIcon
        }
        alt="pdf"
      />
      <label htmlFor={props.fileUploadKey+"inputId"} className="pdf_label">
       {msg}
      </label>
      {loadingUpload && ovalLoader}
      <input
        type="file"
        id={props.fileUploadKey+"inputId"}
        style={{display: 'none'}}
        onChange={e => handleFileChange(e)} disabled={disable} title="Engin mynd valin"
      />
    </div>
  );
};

export default FileUploadPdf2;