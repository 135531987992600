import React, {useState} from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Settings} from '@mui/icons-material';
import {logout} from "../../../store/actions/auth";


const SettingsDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const store = JSON.parse(localStorage.getItem('p_user'));
  const companies = store ? store.companies : [];

  const onChangeCompany = (companyId) => {
    let objStore = JSON.parse(localStorage.getItem('p_user'));
    let copyStore = {...objStore};
    let selected = objStore.companies.find(f => f.id === companyId);
    copyStore.companyId = companyId;
    copyStore.companies = copyStore.companies.filter(f => f.id !== companyId);
    copyStore.companies.unshift(selected);
    localStorage.setItem('p_user', JSON.stringify(copyStore));
    window.location.reload();
  };

  const handleLogout = () => {
    dispatch(logout(navigate));
  };

  const redirectToGreenStep = () => {
    navigate('/adgerdir', { state: {"showTour": true}});
  };

  return (
    <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="settingsDropdown" title={t('settings')}>
      <DropdownToggle className="btn header-toggle waves-effect" tag="button">
        <Settings/>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {
          companies && companies.map((company, companyIndex) =>
            <DropdownItem
              className={`item companyItem ${store.companyId === company.id && 'active'}`}
              key={'settingsDropdownCompany'+companyIndex}
              onClick={() => onChangeCompany(company.id)}
            >
              {company.name}
            </DropdownItem>
          )
        }
        <DropdownItem tag="a" className="item" onClick={() => redirectToGreenStep()}>
          {t('begin_guidance')}
        </DropdownItem>
        <DropdownItem tag="a" href="/profile" className="item">
          {t('settings')}
        </DropdownItem>
        <DropdownItem onClick={() => handleLogout()} className="item">
          {t('logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
};

export default SettingsDropdown