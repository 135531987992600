import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import Swal from "sweetalert";
import {Input} from "reactstrap";


import {AddCircle, Check} from "@mui/icons-material";
import ScoreAndFavorite from "../scoreAndFavorite";
import i18n from "../../../../i18n";

import {
  AddCommas,
  AddCommasToFloat,
  RemoveNonNumeric,
  RemoveNonNumericForSave
} from "../../../Carbon/components/helpers";
import {
  createCompanyCarbonEmission3rdparty, deleteCompanyCarbonEmission3rdparty,
  updateCompanyCarbonEmission3rdparty
} from "../../../../store/actions/carbon";


import pdfIcon from "../../../../assets/images/pdf1.png";
import FileUploadPdf from "../../../../components/Common/CustomUpload/FileUploadPdf";

import {customDropdownCO2Styles} from "../../../../helpers/dropdown";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line
const scope3Data = {
  'work_transportation': false,
    'staff_business': false,
    'rental': false,
    'domestic_transportation':false,
    'waste': false,
    'purchased_transportation': false,
    'other': false,
};

const GreenStep154 = (props) => {
  const {item, descHasLink, desc} = props;
  const { t } = useTranslation();
  const currentLang = i18n.language || 'is';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;

  const {companyEmissions, emissions3rdparty} = useSelector( state => state.carbon );

  const [carbonYearOptions, setCarbonYearOptions] = useState([
    {label: 2020, value: '2020'},
    {label: 2021, value: '2021'},
    {label: 2022, value: '2022'},
    {label: 2023, value: '2023'}
  ]);
  const [addNewCarbon, setAddNewCarbon] = useState(false);


  const [newCarbonId, setNewCarbonId] = useState(0);
  const [newCarbonError, setNewCarbonError] = useState(false);
  const [newCarbonState, setNewCarbonState] = useState({
    year: '',
    name: '',
    scope1: '',
    scope2: '',
    scope3: '',
    scope3_data: scope3Data,
    pdf: '',
    link: ''
  });

  useEffect(() => {
    if(emissions3rdparty && emissions3rdparty.results) {
      if(emissions3rdparty.results.length>0) {
        const myArrayFiltered = carbonYearOptions.filter((el) => {
          return emissions3rdparty.results.every((f) => {
            return f.year !== el.label;
          });
        });

        setCarbonYearOptions(myArrayFiltered);
      }
    }
  }, [emissions3rdparty]);

  useEffect(() => {
    if(state && state.addOwnCarbon && state.addOwnCarbon === 154) {
      setAddNewCarbon(true);
    }

  },[state])

  const redirectToCarbon = (showInit = false, showCreate = false) => {
    navigate('/carbon', { state: {"showInit": showInit, "showCreate": showCreate}});
  };

  const onChangeCarbonState = (item, value) => {
    let states = {...newCarbonState};
    states[item] = value;
    setNewCarbonState(states);
  };

  const onChangeNumberState = (item, value) => {
    let states = {...newCarbonState};
    states[item] = AddCommas(RemoveNonNumeric(value));
    setNewCarbonState(states);
  };

  const saveNewThirdPartyCarbon = () => {
    if(newCarbonState.year.length > 2 && newCarbonState.name.length > 2 &&
      newCarbonState.scope1.length > 1 && newCarbonState.scope2.length > 1 && newCarbonState.scope3.length > 1) {
      newCarbonState.scope1 = RemoveNonNumericForSave(newCarbonState.scope1);
      newCarbonState.scope2 = RemoveNonNumericForSave(newCarbonState.scope2);
      newCarbonState.scope3 = RemoveNonNumericForSave(newCarbonState.scope3);
      dispatch(createCompanyCarbonEmission3rdparty({...newCarbonState, 'company_id': firstCompany.id}))
    } else {
      setNewCarbonError(true);
    }
  };

  const editThirdPartyCarbon = (item) => {
    setNewCarbonId(item.id);
    setNewCarbonState({
      year: item.year,
      name: item.name,
      scope1: AddCommas(item.scope1),
      scope2: AddCommas(item.scope2),
      scope3: AddCommas(item.scope3),
      link: item.link,
      scope3_data: item.scope3_data ? JSON.parse(item.scope3_data) : scope3Data
    });
  };

  const updateThirdPartyCarbon = () => {
    if(newCarbonState.year > 0 && newCarbonState.name.length > 2 &&
      newCarbonState.scope1.length > 1 && newCarbonState.scope2.length > 1 && newCarbonState.scope3.length > 1) {
      newCarbonState.scope1 = RemoveNonNumericForSave(newCarbonState.scope1);
      newCarbonState.scope2 = RemoveNonNumericForSave(newCarbonState.scope2);
      newCarbonState.scope3 = RemoveNonNumericForSave(newCarbonState.scope3);
      dispatch(updateCompanyCarbonEmission3rdparty(newCarbonId, newCarbonState));
    } else {
      setNewCarbonError(true);
    }
  };

  const deleteThirdPartyCarbon = (item) => {
    Swal({
      title:t('are_you_sure'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        dispatch(deleteCompanyCarbonEmission3rdparty(item.id, firstCompany.id))
      } else {
        Swal.close();
      }
    });
  };


  const editOwnCarbon = (year) => {
    navigate('/carbon', { state: {"showResult": year}});
  };

  const onChangeScope3Data = (value, item) => {
    let copyScope3Data = {...newCarbonState.scope3_data};
    copyScope3Data[item] = value;
    setNewCarbonState({...newCarbonState, scope3_data: copyScope3Data });
  };

  return (
    <div className="carbonInGreenStepsContainer" key={"bigCategoryCarbonContainer_"+item.id}>
      <ScoreAndFavorite item={item} companyGreenStep={item.new_company_green_steps.length>0 ? item.new_company_green_steps[0] : false}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
        descHasLink ?
          <div dangerouslySetInnerHTML={{
            __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
          }}/>
        :
          desc
        }
      </div>


      {
        companyEmissions && companyEmissions.results.length === 0 &&
        emissions3rdparty && emissions3rdparty.results.length === 0 &&
        <div className="carbonBtns">
          <div className="btn" onClick={() => redirectToCarbon(true)}> {t('pluses_page.calculate_emissions')}</div>
          <div className="btn" onClick={() => setAddNewCarbon(true)}>{t('pluses_page.enter_emissions')}</div>
        </div>
      }

      {companyEmissions && companyEmissions.results && Object.keys(companyEmissions.results).length>0 &&
      <div className="ownCarbonContainer col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        { Object.values(companyEmissions.results)
          .filter(f => typeof f !== 'string')
          .map((item, index) =>
            <div className="carbonItem" key={"ownCarbonItemKey_"+index}>
              <div className="edit categoryTextColor" onClick={() => editOwnCarbon(Object.keys(companyEmissions.results)[index])}>{t('edit')}</div>
              <div className="year categoryTextColor">{t('carbon_page.emissions_for')} {Object.keys(companyEmissions.results)[index]}</div>
              <div className="row">
                <div className="col col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                  <div className="scopes">
                    <div className="scope">
                      <p className="scopeTitle">{t('carbon_page.scope_emissions')} 1</p>
                      {item[1] ? AddCommas(item[1].sum) : 0} CO2 íg
                    </div>
                    <div className="scope">
                      <p className="scopeTitle">{t('carbon_page.scope_emissions')} 2</p>
                      {item[2] ? AddCommas(item[2].sum) : 0} CO2 íg
                    </div>
                    <div className="scope">
                      <p className="scopeTitle">{t('carbon_page.scope_emissions')} 3</p>
                      {item[3] ? AddCommas(item[3].sum) : 0} CO2 íg
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      }

      {emissions3rdparty && emissions3rdparty.results && emissions3rdparty.results.length>0 &&
      <div className="thirdPartyCarbonContainer col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-8">
        { emissions3rdparty.results.map((item, index) => {
          let scope3DataItems = item.scope3_data && item.scope3_data !== "null" ? JSON.parse(item.scope3_data) : scope3Data;

          return (
            <div className="carbonItem" key={"thirdPartyCarbonItemKey_"+index}>
              <div className="year categoryTextColor">{t('carbon_page.emissions_for')} {item.year}</div>
              <div className="carbonName">
                {t('carbon_page.implemented_by')}
                {newCarbonId === item.id ?
                  <Input
                    name="other"
                    type="text"
                    value={newCarbonState.name}
                    className="form-control"
                    onChange={event => onChangeCarbonState("name", event.target.value)}
                    invalid={newCarbonError && newCarbonState.name.length === 0}
                  />
                  :
                  <span>{item.name}</span>
                }

              </div>

              <div className="scopes">
                <div className="scope">
                  <p className="scopeTitle">{t('carbon_page.scope_emissions')} 1</p>
                  {newCarbonId === item.id ?
                    <Input
                      name="other"
                      type="text"
                      value={newCarbonState.scope1}
                      className="form-control"
                      onChange={event => onChangeNumberState("scope1", event.target.value)}
                      invalid={newCarbonError && newCarbonState.scope1.length === 0}
                    />
                    :
                    AddCommas(item.scope1)
                  }
                  <span className="co2Text"> CO2 íg</span>
                </div>
                <div className="scope">
                  <p className="scopeTitle">{t('carbon_page.scope_emissions')} 2</p>
                  {newCarbonId === item.id ?
                    <Input
                      name="other"
                      type="text"
                      value={newCarbonState.scope2}
                      className="form-control"
                      onChange={event => onChangeNumberState("scope2", event.target.value)}
                      invalid={newCarbonError && newCarbonState.scope2.length === 0}
                    />
                    :
                    AddCommas(item.scope2)
                  }
                  <span className="co2Text"> CO2 íg</span>
                </div>
                <div className="scope">
                  <p className="scopeTitle">{t('carbon_page.scope_emissions')} 3</p>
                  {newCarbonId === item.id ?
                    <Input
                      name="other"
                      type="text"
                      value={newCarbonState.scope3}
                      className="form-control"
                      onChange={event => onChangeNumberState("scope3", event.target.value)}
                      invalid={newCarbonError && newCarbonState.scope3.length === 0}
                    />
                    :
                    AddCommas(item.scope3)
                  }
                  <span className="co2Text"> CO2 íg</span>
                </div>
              </div>

              {newCarbonId === item.id ?
                <div className="scope3_data">
                  <span>Vinsamlegast merkið við þá þætti sem hafa verið mældir í umfangi 3</span>

                  <div className="companyCompletedToggleBtns">
                    <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.work_transportation, 'work_transportation')}>
                      <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.work_transportation === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Samgöngur til og frá vinnu</div>
                    </div>

                    <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.staff_business, 'staff_business')}>
                      <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.staff_business === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Viðskiptaferðir starfsfólks</div>
                    </div>

                    <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.rental, 'rental')}>
                      <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.rental === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Notkun leigu- og bílaleigubíla</div>
                    </div>

                    <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.domestic_transportation, 'domestic_transportation')}>
                      <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.domestic_transportation === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Flutningur og dreifing innanlands</div>
                    </div>

                    <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.waste, 'waste')}>
                      <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.waste === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Úrgangur</div>
                    </div>

                    <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.purchased_transportation, 'purchased_transportation')}>
                      <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.purchased_transportation === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Aðkeyptur flutningur og dreifing</div>
                    </div>

                    <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.other, 'other')}>
                      <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.other === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Annað</div>
                    </div>


                  </div>
                </div>
                :
                <div className="scope3_data show">
                  <span>Vinsamlegast merkið við þá þætti sem hafa verið mældir í umfangi 3</span>

                  <div className="companyCompletedToggleBtns">
                    <div className="companyCompleted">
                      <div
                        className={`companyCompletedIcon categoryBorderColor checkbox ${scope3DataItems.work_transportation === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Samgöngur til og frá vinnu</div>
                    </div>

                    <div className="companyCompleted">
                      <div
                        className={`companyCompletedIcon categoryBorderColor checkbox ${scope3DataItems.staff_business === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Viðskiptaferðir starfsfólks</div>
                    </div>

                    <div className="companyCompleted">
                      <div
                        className={`companyCompletedIcon categoryBorderColor checkbox ${scope3DataItems.rental === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Notkun leigu- og bílaleigubíla</div>
                    </div>

                    <div className="companyCompleted">
                      <div
                        className={`companyCompletedIcon categoryBorderColor checkbox ${scope3DataItems.domestic_transportation === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Flutningur og dreifing innanlands</div>
                    </div>

                    <div className="companyCompleted">
                      <div
                        className={`companyCompletedIcon categoryBorderColor checkbox ${scope3DataItems.waste === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Úrgangur</div>
                    </div>

                    <div className="companyCompleted">
                      <div
                        className={`companyCompletedIcon categoryBorderColor checkbox ${scope3DataItems.purchased_transportation === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Aðkeyptur flutningur og dreifing</div>
                    </div>

                    <div className="companyCompleted">
                      <div
                        className={`companyCompletedIcon categoryBorderColor checkbox ${scope3DataItems.other === true}`}>
                        <Check/>
                      </div>
                      <div className="companyCompletedTitle">Annað</div>
                    </div>


                  </div>
                </div>
              }


              {newCarbonId === 0 && item.link && item.link.length>0 && <div className="link">
                { t('pluses_page.link_to_data') }: <a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a>
              </div>}

              {newCarbonId === item.id && <div className="link">
                { t('pluses_page.link_to_data') }:
                <Input
                  name="other"
                  type="text"
                  value={newCarbonState.link}
                  className="form-control"
                  onChange={event => onChangeCarbonState("link", event.target.value)}
                />
              </div>
              }

              {newCarbonId === 0 && item.pdf && item.pdf.length>0 &&
              <div className="pdfContainer">
                <a href={item.pdf} target="_blank" rel="noopener noreferrer">
                  <img className='pdf_icon' src={pdfIcon} alt="PDF file"/>
                  <span className="pdf_name categoryTextColor"> {'file.pdf'}</span>
                </a>
              </div>
              }

              {newCarbonId === item.id &&
                <FileUploadPdf
                  fileUploadKey={"greenStep154_editCarbon_pdf"}
                  onUpload={(data, name) => onChangeCarbonState('pdf', data)}
                  msg={t('pluses_page.pdf')}
                />
              }

              <div className="footer">
                {newCarbonId > 0 &&
                <div className="delete categoryTextColor" onClick={() => deleteThirdPartyCarbon(item)}>
                  Eyða
                </div>
                }

                <div className="update categoryTextColor" onClick={() => newCarbonId === 0 ? editThirdPartyCarbon(item) : updateThirdPartyCarbon()}>
                  {newCarbonId === 0 ? t('edit'): t('save')}
                </div>
              </div>


            </div>
          )
        })}
      </div>
      }

      {addNewCarbon &&
        <div className="addThirdPartyCarbonContainer col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
          <div className="year">
            <span>{t('carbon_page.choose_year')}</span>
            <Select
              isSearchable={false}
              placeholder={t('select')}
              value={{label: newCarbonState.year, value: newCarbonState.year}}
              onChange={(event) => onChangeCarbonState('year', event.value)}
              styles={customDropdownCO2Styles}
              options={carbonYearOptions}
              className={`react-select ${newCarbonError && newCarbonState.year.length === 0 && 'has-error'}`}
            />
          </div>

          <div className="desc">
            {t('carbon_page.who_performed')}
          </div>

          <div className="name">
            <span>{t('carbon_page.implemented_by')}</span>
            <Input
              name="other"
              type="text"
              value={newCarbonState.name}
              className="form-control"
              onChange={event => onChangeCarbonState("name", event.target.value)}
              invalid={newCarbonError && newCarbonState.name.length === 0}
            />
          </div>

          <div className="scope scope1">
            <span>{t('carbon_page.scope_emissions')} 1</span>
            <Input
              name="other"
              type="text"
              value={newCarbonState.scope1}
              className="form-control"
              onChange={event => onChangeNumberState("scope1", event.target.value)}
              invalid={newCarbonError && newCarbonState.scope1.length === 0}
            />
            <span className="co2Text">t CO2-íg</span>
          </div>

          <div className="scope scope2">
            <span>{t('carbon_page.scope_emissions')} 2</span>
            <Input
              name="other"
              type="text"
              value={newCarbonState.scope2}
              className="form-control"
              onChange={event => onChangeNumberState("scope2", event.target.value)}
              invalid={newCarbonError && newCarbonState.scope2.length === 0}
            />
            <span className="co2Text">t CO2-íg</span>
          </div>

          <div className="scope scope3">
            <span>{t('carbon_page.scope_emissions')} 3</span>
            <Input
              name="other"
              type="text"
              value={newCarbonState.scope3}
              className="form-control"
              onChange={event => onChangeNumberState("scope3", event.target.value)}
              invalid={newCarbonError && newCarbonState.scope3.length === 0}
            />
            <span className="co2Text">t CO2-íg</span>
          </div>

          <div className="scope3_data">
            <span>Vinsamlegast merkið við þá þætti sem hafa verið mældir í umfangi 3</span>

            <div className="companyCompletedToggleBtns">
              <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.work_transportation, 'work_transportation')}>
                <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.work_transportation === true}`}>
                  <Check/>
                </div>
                <div className="companyCompletedTitle">Samgöngur til og frá vinnu</div>
              </div>

              <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.staff_business, 'staff_business')}>
                <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.staff_business === true}`}>
                  <Check/>
                </div>
                <div className="companyCompletedTitle">Viðskiptaferðir starfsfólks</div>
              </div>

              <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.rental, 'rental')}>
                <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.rental === true}`}>
                  <Check/>
                </div>
                <div className="companyCompletedTitle">Notkun leigu- og bílaleigubíla</div>
              </div>

              <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.domestic_transportation, 'domestic_transportation')}>
                <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.domestic_transportation === true}`}>
                  <Check/>
                </div>
                <div className="companyCompletedTitle">Flutningur og dreifing innanlands</div>
              </div>

              <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.waste, 'waste')}>
                <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.waste === true}`}>
                  <Check/>
                </div>
                <div className="companyCompletedTitle">Úrgangur</div>
              </div>

              <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.purchased_transportation, 'purchased_transportation')}>
                <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.purchased_transportation === true}`}>
                  <Check/>
                </div>
                <div className="companyCompletedTitle">Aðkeyptur flutningur og dreifing</div>
              </div>

              <div className="companyCompleted" onClick={() => onChangeScope3Data(!newCarbonState.scope3_data.other, 'other')}>
                <div className={`companyCompletedIcon categoryBorderColor checkbox ${newCarbonState.scope3_data.other === true}`}>
                  <Check/>
                </div>
                <div className="companyCompletedTitle">Annað</div>
              </div>


            </div>
          </div>

          <div className="pdf">
            <span>{t('carbon_page.upload_pdf')}</span>
            <FileUploadPdf
              fileUploadKey={"greenStep154_addNewCarbon_pdf"}
              onUpload={(data, name) => onChangeCarbonState('pdf',data)}
              msg={t('pluses_page.pdf')}
            />
          </div>

          <div className="link">
            <span>{ t('pluses_page.link_to_data') }</span>
            <Input
              name="other"
              type="text"
              value={newCarbonState.link}
              className="form-control"
              onChange={event => onChangeCarbonState("link", event.target.value)}
            />
          </div>

          <div className="save" onClick={() => saveNewThirdPartyCarbon()}>{t('save')}</div>
        </div>
      }


      <div className={companyEmissions && companyEmissions.results.length === 0 && emissions3rdparty && emissions3rdparty.results.length === 0 && 'hide'}>
        {Object.keys(companyEmissions.results).length<4 &&
        <div className="addCarbon" onClick={() => redirectToCarbon(false, true)}>
          {t('carbon_page.calculate_more_years')} <AddCircle/>
        </div>
        }
        <div className="addCarbon" onClick={() => setAddNewCarbon(true)}>
          {t('carbon_page.add_emission_data')} <AddCircle/>
        </div>
      </div>


    </div>
  )
};

export default GreenStep154