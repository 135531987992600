import * as actionTypes from '../actionTypes';
import {DELETE_COMPANY_GREEN_STEP_FROM_DASHBOARD_SUCCESS} from "../actionTypes";

const initialState = {
  loading: true,
  dashboard: [],
  loadingMunicipality: false,
  municipality: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function dashboardReducer(state = initialState, action = {}) {
  switch (action.type) {
    
    case actionTypes.GET_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
        dashboard: action.data,
        loading: false
      };
    case actionTypes.GET_DASHBOARD_REPORT_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_DASHBOARD_REPORT_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.UPDATE_COMPANY_GREEN_STEP_FROM_DASHBOARD_SUCCESS:
      let newCopyDashData = {...state.dashboard};
      let findPolicyIndex = state.dashboard.results.policies.findIndex(f => f.id === action.data.results.new_green_step_id);
      if (findPolicyIndex >= 0) {
        newCopyDashData.results.policies[findPolicyIndex].new_company_green_steps[0] = action.data.results
      }
      return {
        ...state,
        dashboard: newCopyDashData,
      };

    case actionTypes.DELETE_COMPANY_GREEN_STEP_FROM_DASHBOARD_SUCCESS:
      let newCopyDashDataDel = {...state.dashboard};
      let findPolicyIndexDel = state.dashboard.results.policies.findIndex(f => f.id === action.data.results.new_green_step_id);
      if (findPolicyIndexDel >= 0) {
        newCopyDashDataDel.results.policies[findPolicyIndexDel].new_company_green_steps = []
      }
      return {
        ...state,
        dashboard: newCopyDashDataDel,
      };


    case actionTypes.GET_DASHBOARD_MUNICIPALITY_START:
      return  {
        ...state,
        loadingMunicipality: true
      };
    case actionTypes.GET_DASHBOARD_MUNICIPALITY_SUCCESS:
      return {
        ...state,
        municipality: action.data,
        loadingMunicipality: false
      };
    case actionTypes.GET_DASHBOARD_MUNICIPALITY_FAIL:
      return  {
        ...state,
        loadingMunicipality: false
      };
      
    default:
      return state;
  }
}
