import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select  from "react-select";
import {useLocation} from "react-router-dom";
import Joyride from 'react-joyride';
import { t } from 'i18next';
import {NavLink, NavItem, Nav, UncontrolledTooltip, Modal} from "reactstrap";

import i18n from "../../i18n";
import {LoaderSpinner} from "../../components/Common/Loader";
import Achievement from "../../components/Common/Achievement2";
import LeafIcon from "../../components/Common/Leaf/leafIcon";

import {
  createCompanyGreenStep,
  updateCompanyGreenStep,
  deleteCompanyGreenStep,
  editComment,
  getGreenStepsNew,
  getGreenStepTips
} from "../../store/actions/greenStep";
import {createUpdateActionPlan, deleteActionPlan} from "../../store/actions/actionPlan";
import {filterDropdownGreenStepStyles} from "../../helpers/dropdown";

import BigCategories from "./bigCategory";
import SmallCategories from "./smallCategory";
import Notes from "./notes";
import CompletedByCategory from "./components/completedByCategory";

import './index.scss';
import {verifyOnboarding} from "../../store/actions/registration";
import InitialGreenSteps from "./components/initialGreenSteps";
import {getMunicipalities} from "../../store/actions/municipality";
import {getCompanyCarbonEmissions, getCompanyCarbonEmissions3rdparty} from "../../store/actions/carbon";


const getPercentByCategory = (mainCategory) => {
  let count = 0;
  let approvedCount = 0;
  let irrelevantCount = 0;

  let sumScore = (data) => {
    data.new_green_steps.forEach(greenStep => {
      count += greenStep.score;
      if(greenStep.new_company_green_steps.length>0) {
        if(greenStep.new_company_green_steps[0].irrelevant === true) {
          irrelevantCount += greenStep.score
        } else {
          approvedCount += greenStep.slider === true ? greenStep.new_company_green_steps[0].slider_score : greenStep.score
        }
      }
    });
  };

  mainCategory.forEach(item => {
    sumScore(item);
    item.categories.length>0 && item.categories.forEach(category => {
      sumScore(category);
    });
  });


  return Math.round((approvedCount*100)/(count-irrelevantCount));
};

const leafColors = [
  {id: 1, color: "#fff", secondColor: "#A7EEBF"},
  {id: 2, color: "#fff", secondColor: "#FFCEAC"},
  {id: 3, color: "#fff", secondColor: "#E3C9FC"}
];

const tourSteps = [
  {
    title: t('onboarding_page.get_ideas'),
    content: (
      <div>
        {t('onboarding_page.green_steps')} <br />
        <b>{t('onboarding_page.green_steps2')}</b> {t('onboarding_page.green_steps3')} <b>{t('onboarding_page.green_steps4')}</b> {t('onboarding_page.green_steps5')}
        <br /><br />
        {t('onboarding_page.rating1')} <br />
        {t('onboarding_page.rating2')} <br />
        <b>{t('onboarding_page.rating3')}</b>. {t('onboarding_page.rating4')} <br />
        {t('onboarding_page.rating5')} <br />
      </div>
    ),
    locale: {next: t('onboarding_page.next')},
    styles: {
      options: {width: 500},
    },
    placement: 'center',
    target: '.greenStepPage',
  },
  {
    title: t('onboarding_page.create_plan'),
    content: (
      <div>
        {t('onboarding_page.create_plan2')} <br />
        {t('onboarding_page.create_plan3')}
        <br /><br />
        {t('onboarding_page.create_plan4')} <br />
        {t('onboarding_page.create_plan5')} <br />
        {t('onboarding_page.create_plan6')} <br />
      </div>
    ),
    locale: {next: t('onboarding_page.next') },
    styles: {
      options: {width: 500},
    },
    placement: 'center',
    target: '.greenStepPage',
  },
  {
    content: <div>{t('onboarding_page.press_heart')}</div>,
    locale: {last: t('onboarding_page.finish')},
    styles: {
      options: {width: 300},
    },
    placement: 'bottom',
    target: '.favorite',
  }
];

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;
  const companySize = firstCompany ? firstCompany.company_size : 1;
  const {state} = useLocation();
  const {loading, greenSteps, categoryIndex, loadingTips, tips} = useSelector( state => state.greenStep );
  const {loadingCompanyEmissions, loadingEmissions3rdparty} = useSelector( state => state.carbon );

  const storageCategory = localStorage.getItem('gp') ? parseInt(localStorage.getItem("gp"), 10) : 0;

  const [activeCategory, setActiveCategory] = useState(state && state.showCategory ? state.categoryIndex : storageCategory > 0 ? storageCategory : 0);
  const [filterCategoryNames, setFilterCategoryNames] = useState( []);
  const [activeNotes, setActiveNotes] = useState( false);
  const [totalPercent, setTotalPercent] = useState([0,0,0]);
  const [showAchievement, setShowAchievement] = useState(false);

  const [filterSize, setFilterSize] = useState({label: t('pluses_page.levelAll'), label2: 'levelAll', value: 0});
  const [filterCategory, setFilterCategory] = useState( {label: 'Allir flokkar', name: 'Allir flokkar', name_en: 'All steps', value: 'Allir flokkar'});
  const [activeFilter, setActiveFilter] = useState( {label: t('pluses_page.uncompleted_steps'), label2: 'uncompleted_steps', value: 'not_finished'});
  const [filterStep, setFilterStep] = useState( {label: t('pluses_page.small_step'), label2: 'small_step', value: 'small_step'});

  const [bigCategories, setBigCategories] = useState([]);
  const [bigCategoryData, setBigCategoryData] = useState(null);

  const [smallCategories, setSmallCategories] = useState([]);
  const [smallCategoryData, setSmallCategoryData] = useState(null);
  const [smallCategoriesName, setSmallCategoriesName] = useState(null);

  const [tourRun, setTourRun] = useState(false);
  const [alert, setAlert] = useState('');
  const [alert2, setAlert2] = useState('');

  const sortingData = (event, type, firstLoad = false, bigData = [], smallData = []) => {
    let newBigData = firstLoad === true ? bigData : bigCategories
    let newSmallData = firstLoad === true ? smallData : smallCategories;
    let filterSizeValue = filterSize.value;
    let filterCategoryValue = filterCategory.value;

    if(type === 'size') {
      setFilterSize(event);
      filterSizeValue = event.value;
    } else if(type === 'category') {
      setFilterCategory(event);
      filterCategoryValue = event.value;
    }

    if(filterCategoryValue !== 'Allir flokkar') {
      let findIndex = bigCategories.findIndex(f => f.name === filterCategoryValue)
      newBigData = findIndex>=0 ? [bigCategories[findIndex]] : []
    }

    if(filterCategoryValue !== 'Allir flokkar') {
      let findIndex = smallCategories.findIndex(f => f.name === filterCategoryValue)
      newSmallData = findIndex>=0 ? [bigCategories[findIndex]] : []
    }

    if(filterSizeValue>0) {
      newBigData = newBigData.map(item => {
        return {
          ...item,
          'new_green_steps_not_completed': item.new_green_steps_not_completed.filter(f => f[`company_size${companySize}`] === filterSizeValue),
          'new_green_steps_completed': item.new_green_steps_completed.filter(f => f[`company_size${companySize}`] === filterSizeValue),
        }
      });

      newSmallData = newSmallData.map(item => {
        return {
          ...item,
          'new_green_steps_not_completed': item.new_green_steps_not_completed.filter(f => f[`company_size${companySize}`] === filterSizeValue),
          'new_green_steps_completed': item.new_green_steps_completed.filter(f => f[`company_size${companySize}`] === filterSizeValue),
        }
      });
    }

    bigData.length === 0 && window.scrollY > 450 && window.scrollTo(0, 350);
    setBigCategoryData(newBigData);
    setSmallCategoryData(newSmallData);
  }

  const getData = (category, firstLoad = true) => {
    let totalCopy = [...totalPercent];
    greenSteps && greenSteps.results && greenSteps.results.forEach((greenStep, greenStepIndex) => {
      totalCopy[greenStepIndex] = getPercentByCategory(greenStep.children_category)
    });

    if(totalPercent[0] !== 0 && totalPercent[1] !== 0 && totalPercent[2] !== 0) {
      let oldPercent = (totalPercent[0]+totalPercent[1]+totalPercent[2])/3;
      let newPercent = (totalCopy[0]+totalCopy[1]+totalCopy[2])/3;
      let remainder = newPercent % 5;
      let percentToAlert = newPercent - remainder;
      if(percentToAlert > oldPercent) {
        window.scrollTo(0, 0);
        setShowAchievement(true);
      }
    }

    setTotalPercent(totalCopy);

    let bigData = [];
    let smallData = [];
    let smallCategoriesNameData = [];
    let categoryNames = [];


    greenSteps && greenSteps.results && greenSteps.results[category].children_category.forEach(item => {
      if(item.icon !== null) {
        bigData.push({
          ...item,
          "new_green_steps_not_completed": item.new_green_steps.filter(f =>
            f.id === 205 || f.id === 206 || f.id === 154 || f.id === 178 || f.id === 218 || f.id === 220 ||
            f.new_company_green_steps.length === 0 ||
            (firstLoad && f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].hasOwnProperty('addedRecent')) ||
            (f.slider === true && f.new_company_green_steps.length > 0 && f.score > f.new_company_green_steps[0].slider_score)
          ),
          "new_green_steps_completed": item.new_green_steps.filter(f =>
            (f.id !== 205 && f.id !== 206 && f.id !== 154 && f.id !== 178 && f.id !== 218 && f.id !== 220) &&
            (f.slider === true ? f.new_company_green_steps.length > 0 && f.score === f.new_company_green_steps[0].slider_score : f.new_company_green_steps.length > 0) &&
            (
              (f.new_company_green_steps[0].irrelevant === false || f.new_company_green_steps[0].irrelevant === null) ||
              !f.new_company_green_steps[0].hasOwnProperty('irrelevant')
            )
          ),
          "new_green_steps_completed_irrelevant": item.new_green_steps.filter(f => f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].irrelevant === true),
          "categories": item.categories,
        })
      } else {
        let tempSmall = [];
        if(item.categories.length>0) {
          item.categories.forEach(c => tempSmall.push(...c.new_green_steps))
          smallCategoriesNameData.push(...item.categories)
        } else if(item.new_green_steps.length>0) {
          tempSmall.push(...item.new_green_steps)
          smallCategoriesNameData.push(item)
        }

        tempSmall.length>0 && smallData.push({
          ...item,
          "all_categories": item.categories.length>0 ? item.categories : item,
          "new_green_steps_not_completed": tempSmall.filter(f => f.new_company_green_steps.length === 0 || (firstLoad && f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].hasOwnProperty('addedRecent') && !f.new_company_green_steps[0].hasOwnProperty('comment') && !f.new_company_green_steps[0].hasOwnProperty('reason'))),
          "new_green_steps_completed": tempSmall.filter(f => f.new_company_green_steps.length > 0 && ((f.new_company_green_steps[0].irrelevant === false || f.new_company_green_steps[0].irrelevant === null) || !f.new_company_green_steps[0].hasOwnProperty('irrelevant'))),
          "new_green_steps_completed_irrelevant": tempSmall.filter(f => f.new_company_green_steps.length > 0 && f.new_company_green_steps[0].irrelevant === true),
        })
      }

      if(categoryNames.findIndex(f => f.name === item.name) === -1) {
        categoryNames.push({label: item.name, name: item.name, name_en: item.name_en, value: item.name});
      }
    });

    categoryNames.push({label: 'Allir flokkar', name: 'Allir flokkar', name_en: 'All steps', value: 'Allir flokkar'});
    setFilterCategoryNames(categoryNames);
    setBigCategories(bigData);
    setSmallCategories(smallData);
    setSmallCategoriesName(smallCategoriesNameData);

    sortingData('', '', true, bigData, smallData);

    // if(storageCategory === category && localStorage.getItem('gp2')){
    //   let storageBigCategory = parseInt(localStorage.getItem("gp2"), 10);
    //   setActiveBigCategory(storageBigCategory);
    //   setBigCategoryData([bigData[storageBigCategory]]);
    // } else {
    //   setBigCategoryData(bigData);
    // }
    //
    // if(activeCategory === 0 && activeBigCategory === 0) {
    //   setSmallCategoryData([])
    // } else {
    //   setSmallCategoryData(smallData);
    // }

  };

  const handleShowTour = () => {
    setAlert2('');
    setTourRun(true);
    dispatch(verifyOnboarding(companyId));
  };

  const handleSkipTour = () => {
    setAlert2('');
    dispatch(verifyOnboarding(companyId));
  };

  const handleShowFirstTour = () => {
    setAlert('');
    setAlert2(
      <Modal isOpen={true} centered backdrop={"static"} className="tourFistStep" style={{maxWidth: '500px', width: '100%'}}>
        <div className="modal-body">
          <div className="title"> {t('onboarding_page.welcome')} {userData.user.name} </div>
          <div className="copy">
            {t('onboarding_page.welcome1')} <br/>
            {t('onboarding_page.welcome2')}<br/><br/>
            {t('onboarding_page.welcome3')}
          </div>
          <div className="btns">
            <div className="btn skip" onClick={() => handleSkipTour()}>{t('onboarding_page.check_out')}</div>
            <div className="btn go" onClick={() => handleShowTour()}>{t('onboarding_page.start_navigation')}</div>
          </div>
          <div className="copyBottom">
            {t('onboarding_page.welcome4')}
          </div>
        </div>
      </Modal>
    );
  };

  const toggleCategory = (tab) => {
    if (activeCategory !== tab) {
      window.scrollTo(0, 0);
      setActiveNotes(false);
      setActiveCategory(tab);
      setFilterSize({label: t('pluses_page.levelAll'), label2: 'levelAll', value: 0});
      setFilterCategory( {label: 'Allir flokkar', name: 'Allir flokkar', name_en: 'All steps', value: 'Allir flokkar'});
      setFilterStep( {label: t('pluses_page.small_step'), label2: 'small_step', value: 'small_step'});
      setActiveFilter({label: t('pluses_page.uncompleted_steps'), label2: 'uncompleted_steps', value: 'not_finished'});

      getData(tab, false);

      localStorage.setItem('gp', tab);
      localStorage.removeItem('gp2');
    }
  };

  const isSticky = (e) => {
    const header = document.querySelector('.headerRow');
    if(header) {
      window.scrollY >= 350 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    }
  };

  useEffect(() => {
    dispatch(getGreenStepsNew(companyId));
    dispatch(getGreenStepTips());
    dispatch(getCompanyCarbonEmissions(companyId));
    dispatch(getCompanyCarbonEmissions3rdparty(companyId));
    firstCompany && firstCompany.municipality_id>0 && firstCompany.type === null && dispatch(getMunicipalities());
    firstCompany && firstCompany.onboarded === false && setAlert(<InitialGreenSteps handleClose={() => handleShowFirstTour()} />)
  }, []);

  useEffect(() => {
    if(categoryIndex>0) setActiveCategory(categoryIndex);
  }, [categoryIndex]);

  useEffect(() => {
    if(greenSteps && greenSteps.results) {
      getData(activeCategory);
    }
  }, [greenSteps])

  useEffect(() => {
    if(greenSteps && state && state.addOwnCarbon && state.mainCategory>0) {
      toggleCategory(state.mainCategory);
    }
  },[state, greenSteps]);

  useEffect(() => {
    if(greenSteps && state && state.showTour) {
      handleShowFirstTour();
    }
  },[state, greenSteps]);

  // Sticky Filter Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  if (loading || !greenSteps || !greenSteps.results || loadingTips || loadingCompanyEmissions || loadingEmissions3rdparty) return (<LoaderSpinner/>);

  const handleCheck = (data) => {
    dispatch(createCompanyGreenStep(data, companyId, activeCategory));
  };

  const handleUpdate = (data) => {
    dispatch(updateCompanyGreenStep(data, companyId, activeCategory));
  };

  const handleUnCheck = (id) => {
    dispatch(deleteCompanyGreenStep(id, activeCategory, 'deleteCompletely'));
  };

  const addActionPlan = (title, id, startDate, endDate) => {
    let newData = {
      "company_id": companyId,
      "action": title,
      "type": 'green_step',
      "priority": null,
      "start_date": startDate,
      "end_date": endDate,
      "plan_goal_id": null,
      "subgoal": "",
      "status": null,
      "user_ids": null,
      "comment": null,
      "new_green_step_id": id
    };
    dispatch(createUpdateActionPlan(false, newData, activeCategory));
  };

  const handelDeleteActionPlan = (id) => {
    dispatch(deleteActionPlan(id, companyId, activeCategory))
  };

  const handleAddComment = (id, comment, type) => {
    dispatch(editComment(id, comment, activeCategory, type));
  };

  const changeFilterStep = (event) => {
    window.scrollY > 450 && window.scrollTo(0, 350);
    setFilterStep(event)
  }

  return (
    <div className="page-content greenStepPage" data-category={activeCategory}>
      {alert2}
      {alert}

      {showAchievement && <Achievement close={() => setShowAchievement(false)}/>}

      <Joyride
        continuous
        hideCloseButton
        hideSkipButton
        hideBackButton
        run={tourRun}
        showProgress
        steps={tourSteps}
        styles={{options: {zIndex: 10000}}}
      />

      <div className="headerRow">
        <div className="row">
          <div className="col col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <div className="categories">
              <Nav tabs className="nav-tabs-custom nav-justified">
                {greenSteps && greenSteps.results && greenSteps.results.map((greenStep, greenStepIndex) =>
                  <NavItem
                    className={`${activeCategory === greenStepIndex ? 'active' : ''}`}
                    category={greenStep.id}
                    key={'nav_category_' + greenStepIndex}
                    name={greenStep.name}
                  >
                    <NavLink onClick={() => {
                      toggleCategory(greenStepIndex)
                    }}>
                      <div className="progress" style={{width: `${totalPercent[greenStepIndex]}%`}}></div>

                      <span className="leafPercent">
                      {totalPercent[greenStepIndex]}%
                    </span>

                      <span className="leafName">
                      {greenStep["name_" + currentLang] ? greenStep["name_" + currentLang] : greenStep.name}
                    </span>

                      <LeafIcon
                        key={"leaf_item" + greenStepIndex}
                        id={"nav_tooltip_leaf_item" + greenStepIndex}
                        className="leafIcon"
                        colors={leafColors.find(color => color.id === greenStep.id)}
                        percentage={totalPercent[greenStepIndex] > 0 ? totalPercent[greenStepIndex] / 100 : 0}
                      />

                      <UncontrolledTooltip placement="top" target={'nav_tooltip_leaf_item' + greenStepIndex}>
                        {totalPercent[greenStepIndex]}%
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </div>

            <div className="progressContainer">
              <div className="progressBar" style={{'--p': (totalPercent[0] + totalPercent[1] + totalPercent[2]) / 3}}>
                <span className="first"></span><span></span><span></span><span></span><span></span>
                <span></span><span></span><span></span><span></span><span></span>
                <span></span><span></span><span></span><span></span><span></span>
                <span></span><span></span><span></span><span></span><span className="last"></span>
              </div>
              <div className="footer">
                <span>5%</span><span>10%</span><span>15%</span><span>20%</span><span>25%</span>
                <span>30%</span><span>35%</span><span>40%</span><span>45%</span><span>50%</span>
                <span>55%</span><span>60%</span><span>65%</span><span>70%</span><span>75%</span>
                <span>80%</span><span>85%</span><span>90%</span><span>95%</span><span>100%</span>
              </div>
            </div>

            <div className="filters">
              <div className="filter level">
                <span>{t('pluses_page.difficulty_level')}</span>
                <Select
                  isSearchable={false}
                  value={filterSize ? {...filterSize, label: t('pluses_page.' + filterSize.label2)} : {}}
                  onChange={(event) => sortingData(event, 'size')}
                  styles={filterDropdownGreenStepStyles(activeCategory)}
                  options={[
                    {label: t('pluses_page.level1'), label2: 'level1', value: 1},
                    {label: t('pluses_page.level2'), label2: 'level2', value: 2},
                    {label: t('pluses_page.level3'), label2: 'level3', value: 3},
                    {label: t('pluses_page.levelAll'), label2: 'levelAll', value: 0},
                  ]}
                />
              </div>
              <div className="filter category">
                <span>{t('pluses_page.subcategories')}</span>
                <Select
                  isSearchable={false}
                  value={{
                    ...filterCategory,
                    label: filterCategory["name_" + currentLang] ? filterCategory["name_" + currentLang] : filterCategory.name
                  }}
                  onChange={(event) => sortingData(event, 'category')}
                  styles={filterDropdownGreenStepStyles(activeCategory)}
                  options={
                    filterCategoryNames.map((item) => {
                      return {...item, label: item["name_" + currentLang] ? item["name_" + currentLang] : item.name}
                    })
                  }
                />
              </div>
              <div className="filter step">
                <span>{t('pluses_page.actionsSize')}</span>
                <Select
                  isSearchable={false}
                  value={filterStep ? {...filterStep, label: t('pluses_page.' + filterStep.label2)} : {}}
                  onChange={(event) => changeFilterStep(event)}
                  styles={filterDropdownGreenStepStyles(activeCategory)}
                  options={[
                    {label: t('pluses_page.big_step'), label2: 'big_step', value: 'big_step'},
                    {label: t('pluses_page.small_step'), label2: 'small_step', value: 'small_step'},
                    {label: t('pluses_page.all_step'), label2: 'all_step', value: 'all_step'}
                  ]}
                />
              </div>
              <div className="filter status">
                <Select
                  isSearchable={false}
                  value={activeFilter ? {...activeFilter, label: t('pluses_page.' + activeFilter.label2)} : {}}
                  onChange={(event) => setActiveFilter(event)}
                  styles={filterDropdownGreenStepStyles(activeCategory)}
                  options={[
                    {label: t('pluses_page.uncompleted_steps'), label2: 'uncompleted_steps', value: 'not_finished'},
                    {label: t('pluses_page.completed_steps'), label2: 'completed_steps', value: 'finished'}
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {(filterStep.value === 'big_step' || filterStep.value === 'all_step') && activeFilter.value === 'not_finished' && activeNotes === false &&
        <div className="row bigGreenStepsRow">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <BigCategories
              mainCategoryIndex={activeCategory}
              bigCategory={bigCategoryData}
              check={(data) => handleCheck(data)}
              unCheck={(id) => handleUnCheck(id)}
              update={(data) => handleUpdate(data)}
              addComment={(id, comment, type) => handleAddComment(id, comment, type)}
              addActionPlan={(title, id, startDate, endDate) => addActionPlan(title, id, startDate, endDate)}
              deleteActionPlan={(id) => handelDeleteActionPlan(id)}
            />
          </div>
        </div>
      }

      {(filterStep.value === 'big_step' || filterStep.value === 'all_step') && activeFilter.value === 'finished' && activeNotes === false &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <CompletedByCategory
              data={bigCategoryData}
              mainCategoryIndex={activeCategory}
              type="big"
            />
          </div>
        </div>
      }

      {(filterStep.value === 'small_step' || filterStep.value === 'all_step') && activeFilter.value === 'not_finished' && activeNotes === false &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <SmallCategories
              mainCategoryIndex={activeCategory}
              notCompleted={smallCategoryData}
              smallCategoriesName={smallCategoriesName}
              check={(data) => handleCheck(data)}
              unCheck={(id) => handleUnCheck(id)}
              addComment={(id, comment, type) => handleAddComment(id, comment, type)}
              addActionPlan={(title, id, startDate, endDate) => addActionPlan(title, id, startDate, endDate)}
              deleteActionPlan={(id) => handelDeleteActionPlan(id)}
            />
          </div>
        </div>
      }

      {(filterStep.value === 'small_step' || filterStep.value === 'all_step') && activeFilter.value === 'finished' && activeNotes === false && activeCategory < 2 &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <CompletedByCategory
              data={smallCategoryData}
              mainCategoryIndex={activeCategory}
              type="small"
            />
          </div>
        </div>
      }

      {activeNotes === true &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <Notes
              tips={tips}
              mainCategoryIndex={activeCategory + 1}
            />
          </div>
        </div>
      }


    </div>
  )
};

export default Index