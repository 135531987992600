import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import {Close, Favorite} from '@mui/icons-material';
import {CardBody, Card, Modal, UncontrolledTooltip, CarouselItem, CarouselControl, Carousel} from "reactstrap"
import i18n from "../../i18n";

import logo from "../../assets/images/logo.png"

import LeafIcon from "../../components/Common/Leaf/leafIcon";
import RenderPdfFileContainer from "../Green_steps/components/renderPdfFileContainer";
import {Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import { t } from 'i18next';

// const months = {
//   "January": 'Janúar', "February": 'Febrúar', "March": 'Mars', "April": 'Apríl',
//   "May": 'Maí', "June": 'Júní', "July": 'Júlí', "August": "Ágúst",
//   "September": "September", "October": "Október", "November": "Nóvember", "December": "Desember"
// };

const leafColors = [
  {id: 1, color: "#4FC878", secondColor: "#A7EEBF"},
  {id: 2, color: "#FD974F", secondColor: "#FFCEAC"},
  {id: 3, color: "#4433CD", secondColor: "#E3C9FC"}
];

const renderGreenStepCircleChart = (item) => {
  if(!item) return;
  let bgColor = '#293450';
  let fillColor = '#293450';
  let textColor = '#ffffff';
  let itemColor = leafColors.find(leaf => leaf.id === item.id );

  if(item.total_category_green_steps === 100) {
    bgColor = itemColor.color; fillColor = itemColor.color; textColor="#ffffff"
  } else {
    bgColor = itemColor.secondColor; fillColor = itemColor.color; textColor=itemColor.color
  }

  let chartOptions = {
    chart: {
      offsetY: 0,
      type: "radialBar",
      zoom: {
        autoScaleYaxis: true
      }
    },
    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: 0,
        hollow: {margin: 12, size: "70%", background: bgColor},
        track: {
          background: '#fff'
        },
        dataLabels: {
          name: {show: false},
          value: {offsetY: 5, color: textColor, fontSize: "16px", show: true}
        }
      }
    },
    fill: {type: 'solid', colors: [fillColor]},
    stroke: {lineCap: "round"}
  };

  return <ReactApexChart options={chartOptions} series={[item?.total_category_green_steps || 0]} type="radialBar" height={155}/>
};

// const greenStepChartOptions = (period, data) => {
//   return {
//     colors : ['#009448', '#4FC878', '#FD974F', '#4433CD'],
//     chart: {
//       height: 300,
//       type: 'line',
//       zoom: {enabled: false},
//       toolbar: {show: false,}
//     },
//     dataLabels: {enabled: false},
//     stroke: {width: [6,6,6,6,6,6], curve: 'straight'},
//     legend: {offsetY: 6, offsetX: 10, horizontalAlign: 'left'},
//     markers: {size: 0, hover: {sizeOffset: 6}},
//     xaxis: {
//       categories: period === 1 ?
//         Object.keys(data).slice(0).slice(-6).map(item => i18n.language === 'en' ? item : months[item]) :
//         Object.keys(data).map(item => i18n.language === 'en' ? item : months[item]),
//       offsetY: 8,
//     },
//     yaxis: {
//       min: 0,
//       max: 100,
//       tickAmount: 5,
//       labels: {
//         formatter: (val) => { return val+'%' },
//         style: {fontSize: '12px',},
//       },
//     }
//   }
// };

const carbonEmissionChartCircle = (data) => {
  if(!data) return;
  let item = Object.values(data)[0];

  let data01 = [
    { name: t('carbon_page.scope')+' 1', value: item[1] ? parseFloat(item[1].sum) : 0, color: '#83DEA2' },
    { name: t('carbon_page.scope')+' 2', value:  item[2] ? parseFloat(item[2].sum) : 0, color: '#4FC878' },
    { name: t('carbon_page.scope')+' 3', value: item[3] ? parseFloat(item[3].sum) : 0, color: '#009448' },
  ];

  let sum = data01.reduce((a, {value}) => a + value, 0).toFixed(1);

  let RADIAN = Math.PI / 180;
  let renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, fill, name }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    let newPercent = (percent * 100).toFixed(1);

    return (
      <text x={x > cx ? x+5 : x-5} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" >
        {name.length>10 ? name.substring(0,10)+'...' : name} - {newPercent.replace(".", ',')}%{' '}
      </text>
    );
  };

  let CustomLabel = ({viewBox, value1, value2}) => {
    const {cx, cy} = viewBox;
    return (
      <>
        <text x={cx} y={cy-5} fill={'#009448'} textAnchor={'middle'} dominantBaseline="central">
          {value1.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
        </text>
        <text x={cx} y={cy+10} fill={'#009448'} textAnchor={'middle'} dominantBaseline="central">
          {value2}
        </text>
      </>
    )
  };

  return <ResponsiveContainer width="100%" height="100%">
    <PieChart height={150} margin={{top: 15, right: 15, left: 15, bottom: 15}}>
      <Pie
        dataKey="value"
        data={data01}
        cx="50%"
        cy="50%"
        innerRadius={40}
        fill="#8884d8"
        paddingAngle={5}
        label={renderCustomizedLabel}
      >
        {data01.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          )
        )}
        <Label width={30} position="center" content={<CustomLabel value1={sum} value2={t('carbon_page.kg_co2_eq')}/>}>
        </Label>
      </Pie>
      <Tooltip formatter={value => `${parseFloat(value).toFixed(2).replace(".", ',')}`}  />
    </PieChart>
  </ResponsiveContainer>
};

const carbonEmissionChartLine = (data) => {
  if(!data) return;
  let labels = [];
  let series = [{name: t('carbon_page.total_emissions'), data: []}];
  let series1Colors = ['#4FC878', '#007338'];

  Object.keys(data).forEach(function(key, index) {
    labels.push(key);
    series[0].data.push(data[key] ? parseFloat(data[key].total_sum) : 0);
  });

  let chartOptions = {
    colors : series1Colors,
    chart: {
      height: 300,
      type: 'line',
      zoom: {enabled: false},
      toolbar: {show: false,}
    },
    dataLabels: {enabled: false},
    stroke: {width: [6,6,6,6,6,6], curve: 'straight'},
    legend: {offsetY: 6, offsetX: 60, horizontalAlign: 'left'},
    markers: {size: 0, hover: {sizeOffset: 6}},
    xaxis: {
      categories: labels,
      offsetY: 8,
    },
    yaxis: {
      labels: {
        formatter: (val) => {return val+' ' +t('carbon_page.kg_co2_eq')},
        style: {fontSize: '12px', color: '#4FC878'},
      },
    }
  };

  return <ReactApexChart options={chartOptions} series={series} type="line" height={300}/>
};



const DashboardCompany = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const storageData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = storageData ? storageData.companyId : 0;
  const firstCompany = storageData && storageData.companies.length>0 ? storageData.companies[0] : false;
  const isInstitution = firstCompany && firstCompany.municipality_id>0 && firstCompany.type === null;
  const currentLang = i18n.language || 'is';
  const {dashboard} = useSelector(state => state.dashboard);
  const {companyEmissions} = useSelector( state => state.carbon );
  const {municipalities} = useSelector(state => state.municipality);

  const [greenStepByMonth, setGreenStepByMonth] = useState([]);
  const [greenStepByYear, setGreenStepByYear] = useState([]);
  // const [greenStepPeriod, setGreenStepPeriod] = useState(1);

  const [activeTipIndex, setActiveTipIndex] = useState(0);

  const [alert, setAlert] = useState(null);
  const [slideAnimating, setSlideAnimating] = useState(false);

  useEffect( () => {
    if(dashboard.results && greenStepByMonth.length>0 && greenStepByYear.length>0) {
      renderGreenStepChartData();
    }
  }, [i18n.language])

  useEffect(() => {
    if(dashboard.results){
      renderGreenStepChartData();
    }
  },[dashboard.results])

  const renderGreenStepChartData = () => {
    let greenStepsMonth = [];
    let greenStepsYear = [];
    if(dashboard.results && Object.keys(dashboard.results.new_total_green_steps_2023).length >0) {
      greenStepsMonth.push({name: currentLang === 'is' ? "Allir flokkar" : 'All categories', data: Object.values(dashboard.results.new_total_green_steps_2023).slice(0).slice(-6)})
      greenStepsYear.push({name: currentLang === 'is' ? "Allir flokkar" : 'All categories', data: Object.values(dashboard.results.new_total_green_steps_2023)})
    }
    if(dashboard.results && dashboard.results.new_green_step_category.length >0) {
      dashboard.results.new_green_step_category.forEach(item => {
        greenStepsMonth.push({name: item["name_"+currentLang] ? item["name_"+currentLang] : item.name, data: Object.values(item.per_2023).slice(0).slice(-6)});
        greenStepsYear.push({name: item["name_"+currentLang] ? item["name_"+currentLang] : item.name, data: Object.values(item.per_2023)});
      })
    }

    greenStepsMonth.length>0 && setGreenStepByMonth(greenStepsMonth);
    greenStepsYear.length>0 && setGreenStepByYear(greenStepsYear);
  }

  const showWidgetModal = () => {
    setAlert(
      <Modal isOpen={true} size="md" toggle={() => {setAlert(null)}} centered className="dashboardWidgetModal">
        <div className="modal-header">
          <p className="t1">Birtu þinn árangur í Laufinu!</p>
          <p className="t2">Afritaðu kóðann til að bæta viðmótseiningu (e. widget) inná vefsíðu fyrirtækisins</p>
          <Close className="modal-close-icon" onClick={() => setAlert(null)} />
        </div>
        <div className="modal-body">
          <div>
            {`<iframe id="laufid_widget" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="NO" src="https://laufid-widget.vercel.app/company/progress/${companyId}" style={{position: 'fixed', bottom: '10px', right: '10px', width: '205px', height: '135px', zIndex: '9999'}}> </iframe>`}
          </div>
        </div>
      </Modal>
    );
  }

  const redirectToGreenStep = (id, index) => {
    navigate('/adgerdir', { state: {"showCategory": true, categoryId: id, categoryIndex: index}});
  };

  const slideNextTip = () => {
    if (slideAnimating) return;
    if(dashboard.results && dashboard.results.tips.length>0) {
      let active = activeTipIndex === dashboard.results.tips.length-1 ? 0 : activeTipIndex+1;
      setActiveTipIndex(active);
    }
  };
  const slidePrevTip = () => {
    if (slideAnimating) return;
    if(dashboard.results && dashboard.results.tips.length>0) {
      let active = activeTipIndex === 0 ? dashboard.results.tips.length-1 : activeTipIndex-1;
      setActiveTipIndex(active);
    }
  };

  const redirectToCarbon = (showInit = false, showCreate = false) => {
    navigate('/carbon', { state: {"showInit": showInit, "showCreate": showCreate}});
  };

  const redirectToGreenStepCarbon = () => {
    navigate('/adgerdir', { state: {"addOwnCarbon": 154}});
  };

  return (
    <div className="dashboard_company">
      {alert}

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

              <div className="cardTitle">{t('dashboard-progress')}</div>
              <Card className="greenStepsCircle">
                <CardBody>
                  <div className="row">
                    {dashboard.results && dashboard.results.new_green_step_category.map((item, index) =>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4" key={"dashboardGreenStep_"+index}>
                        <div className="chart">
                          {renderGreenStepCircleChart(item)}
                        </div>
                        <div className={`category ${"categoryItem_"+item.id}`}>
                          {item["name_"+currentLang] ? item["name_"+currentLang] : item.name}
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>


              <div className="cardTitle">
                {t('dashboard-emission_in_operation')} {' '}
                {companyEmissions && companyEmissions.results && Object.keys(companyEmissions.results)[0]}
                {companyEmissions && companyEmissions.results && (Object.keys(companyEmissions.results).length > 1) &&
                <React.Fragment> - {Object.keys(companyEmissions.results)[Object.keys(companyEmissions.results).length-1]}</React.Fragment>}
              </div>

              <Card className="greenStepsChart">
                <CardBody
                  className={
                    companyEmissions && companyEmissions.results && Object.keys(companyEmissions.results).length > 0 ?
                      (Object.keys(companyEmissions.results).length === 1) ? 'single' : 'multy'
                    : 'empty'
                  }
                >
                  {
                    companyEmissions && companyEmissions.results && Object.keys(companyEmissions.results).length > 0 ?
                      (Object.keys(companyEmissions.results).length === 1) ? carbonEmissionChartCircle(companyEmissions.results) : carbonEmissionChartLine(companyEmissions.results)
                      : <React.Fragment>
                        <div className="title">
                          {t('dashboard_page.calculate_emissions')}
                        </div>
                        <div className="carbonBtns">
                          <div className="btn" onClick={() => redirectToCarbon(true)}> {t('pluses_page.calculate_emissions')}</div>
                        </div>
                        <div className="carbonBtns">
                          <div className="btn" onClick={() => redirectToGreenStepCarbon()}>{t('pluses_page.enter_emissions')}</div>
                        </div>
                      </React.Fragment>
                  }
                </CardBody>
              </Card>


              <div className="cardTitle">{t('dashboard_page.tips')}</div>
              <Card className="tips">
                {dashboard.results && dashboard.results.tips && dashboard.results.tips.length>0 &&
                <CardBody data-category={dashboard.results.tips[activeTipIndex].category_id}>
                  <Carousel
                    activeIndex={activeTipIndex}
                    next={slideNextTip}
                    previous={slidePrevTip}
                    interval={15000}
                    ride="carousel"
                  >
                    <CarouselControl direction="prev" onClickHandler={slidePrevTip} className="carousel-control left"/>
                    {dashboard.results && dashboard.results.tips.map((item) =>
                      <CarouselItem
                        tag="div"
                        key={'card_carousel_' + item.id}
                        onExiting={() => setSlideAnimating(true)}
                        onExited={() => setSlideAnimating(false)}
                      >
                        <p className="copy">{item["tip_"+currentLang] ? item["tip_"+currentLang] : item.tip}</p>
                      </CarouselItem>
                    )}
                    <CarouselControl direction="next" onClickHandler={slideNextTip} className="carousel-control right"/>
                  </Carousel>
                </CardBody>
                }
              </Card>

            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

              <div className="cardTitle">{t('dashboard-actions_pending')}</div>
              <Card className="actionPlanCard ">
                <CardBody className={dashboard.results && dashboard.results.last_3_action_plans.length < 3 ? 'align-items-start': 'align-items-center'}>
                  <Link to="/action-plan" title={t('action-plan')} className="topbar_icon_main">
                    <div className="actions">
                      {dashboard.results && dashboard.results.last_3_action_plans.map((item, itemIndex) =>
                        <div className="action" key={"dashboardActionPlan_"+itemIndex}>
                          <Favorite className={"categoryItem_"+item.new_green_step.main_category}/>
                          <span>{item.new_green_step ?
                              item.new_green_step["title_"+currentLang] ? item.new_green_step["title_"+currentLang] : item.new_green_step.title
                            : item.action}</span>
                        </div>
                      )}
                    </div>
                  </Link>
                  {dashboard.results && dashboard.results.last_3_action_plans.length === 0 && <div className="emptyText">
                    Eru einhverjar aðgerðir sem þið viljið setja á dagskrá? Smellið á hjartað!
                  </div>}
                </CardBody>
              </Card>

              <div className="cardTitle">{t('dashboard-policies')}</div>
              <Card className="greenStepPolicies">
                {dashboard.results && dashboard.results.policies && dashboard.results.policies.length>0 &&
                <CardBody className={isInstitution && dashboard.results.policies.filter(f => f.new_company_green_steps.length>0).length === 0 ? 'empty' : ''}>

                  {!isInstitution &&
                    <React.Fragment>
                      <div className="categoryItem" data-category="0">
                        {dashboard.results.policies.filter(f => f.main_category === 1).map((item, itemIndex) =>
                          <div className="item" key={"dashGSPoliciesItem_"+itemIndex}>
                            <RenderPdfFileContainer
                              categoryItem={item}
                              mainCategoryIndex={item.main_category-1}
                              key={"dashboardGreenStepPolicies" + itemIndex + "RenderPdfFileContainer1"}
                              dashboard={true}
                            />
                          </div>
                        )}
                      </div>
                      <div className="categoryItem" data-category="1">
                        {dashboard.results.policies.filter(f => f.main_category === 2).map((item, itemIndex) =>
                          <div className="item" key={"dashGSPoliciesItem_"+itemIndex}>
                            <RenderPdfFileContainer
                              categoryItem={item}
                              mainCategoryIndex={item.main_category-1}
                              key={"dashboardGreenStepPolicies" + itemIndex + "RenderPdfFileContainer2"}
                              dashboard={true}
                            />
                          </div>
                        )}
                      </div>
                      <div className="categoryItem" data-category="2">
                        {dashboard.results.policies.filter(f => f.main_category === 3).map((item, itemIndex) =>
                          <div className="item" key={"dashGSPoliciesItem_"+itemIndex}>
                            <RenderPdfFileContainer
                              categoryItem={item}
                              mainCategoryIndex={item.main_category-1}
                              key={"dashboardGreenStepPolicies" + itemIndex + "RenderPdfFileContainer3"}
                              dashboard={true}
                            />
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  }

                  {isInstitution && dashboard.results.policies.filter(f => f.new_company_green_steps.length>0).length>0 ?
                    <React.Fragment>
                      {dashboard.results.policies.filter(f => f.main_category === 1 && f.new_company_green_steps.length > 0).length > 0 &&
                        <div className="categoryItem" data-category="0">
                          {dashboard.results.policies.filter(f => f.main_category === 1 && f.new_company_green_steps.length > 0).map((item, itemIndex) => {
                            return (
                              <div className="item" data-category={item.main_category - 1}>
                                <RenderPdfFileContainer
                                  categoryItem={item}
                                  mainCategoryIndex={item.main_category - 1}
                                  key={"dashboardGreenStepPolicies" + itemIndex + "RenderPdfFileContainer"}
                                  dashboard={true}
                                />
                              </div>
                            )
                          })}
                        </div>
                      }
                      {dashboard.results.policies.filter(f => f.main_category === 2 && f.new_company_green_steps.length > 0).length > 0 &&
                        <div className="categoryItem" data-category="1">
                          {dashboard.results.policies.filter(f => f.main_category === 2 && f.new_company_green_steps.length > 0).map((item, itemIndex) => {
                            return (
                              <div className="item" data-category={item.main_category - 1}>
                                <RenderPdfFileContainer
                                  categoryItem={item}
                                  mainCategoryIndex={item.main_category - 1}
                                  key={"dashboardGreenStepPolicies" + itemIndex + "RenderPdfFileContainer"}
                                  dashboard={true}
                                />
                              </div>
                            )
                          })}
                        </div>
                      }
                      {dashboard.results.policies.filter(f => f.main_category === 3 && f.new_company_green_steps.length > 0).length > 0 &&
                        <div className="categoryItem" data-category="2">
                          {dashboard.results.policies.filter(f => f.main_category === 3 && f.new_company_green_steps.length > 0).map((item, itemIndex) => {
                            return (
                              <div className="item" data-category={item.main_category - 1}>
                                <RenderPdfFileContainer
                                  categoryItem={item}
                                  mainCategoryIndex={item.main_category - 1}
                                  key={"dashboardGreenStepPolicies" + itemIndex + "RenderPdfFileContainer"}
                                  dashboard={true}
                                />
                              </div>
                            )
                          })}
                        </div>
                      }
                    </React.Fragment>
                    : isInstitution && dashboard.results.policies.filter(f => f.new_company_green_steps.length>0).length === 0  ?
                      <div className="emptyText">
                        Hér munu stefnur {' '}
                        {municipalities && municipalities.results && municipalities.results.find(f => f.id === firstCompany.municipality_id)?.name}
                        {' '} birtast, engri stefnu hefur verið bætt við enn.
                      </div>
                      : ''
                  }
                </CardBody>
                }
              </Card>



              <div className="cardTitle">{t('dashboard_page.widget')}</div>
              <Card className="widgetCard" onClick={() => {showWidgetModal()}}>
                <CardBody>
                  <div className="widget">
                    <div style={{ backgroundImage: `url(${logo})` }} className="column logo" />
                    <div className="column leaves">
                      {dashboard.results && dashboard.results.new_green_step_category.map((item, index) =>
                        <div className="leaf_item" key={"secondRow_laufs"+index} onClick={() => redirectToGreenStep(item.id, index)}>
                          <LeafIcon
                            id={"tooltip_leaf_item"+index}
                            key={"leaf_item"+index}
                            colors={leafColors.find(color => color.id === item.id)}
                            percentage={item.total_category_green_steps > 0 ? item.total_category_green_steps/100 : 0}
                          />
                          <UncontrolledTooltip placement="bottom" target={'tooltip_leaf_item'+index}>
                            {item["name_"+currentLang] ? item["name_"+currentLang] : item.name} - {' '}
                            {item.total_category_green_steps}%
                          </UncontrolledTooltip>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="foot">{t('dashboard_page.share')}</div>
                </CardBody>
              </Card>

            </div>
          </div>

        </div>
      </div>


    </div>
  )
};

export default DashboardCompany